import { createStyles } from 'utils/css'
import { mediaQuery } from 'utils/theme/mediaQuery'

export const wrapper = () =>
  createStyles(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }))

export const card = color =>
  createStyles(({ colors }) => ({
    backgroundColor: colors[color + '50'],
    border: `1px solid ${colors[color + '100']}`,
    padding: '8px 16px',
    borderRadius: '8px',
    minWidth: '428px',
    marginTop: '16px',
    maxWidth: '590px',
    [mediaQuery('sm')]: {
      width: '590px'
    },
    width: '428px'
  }))
export const content = () =>
  createStyles(() => ({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'row'
  }))

export const icon = color =>
  createStyles(({ colors }) => ({
    color: colors[color + '300'],
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    alignContent: 'start',
    minWidth: '32px',
    width: '32px',
    padding: '3px 0px'
  }))

export const text = () =>
  createStyles(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '480px'
  }))

export const message = () =>
  createStyles(() => ({
    paddingTop: '8px'
  }))
