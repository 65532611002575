import { WarningAlt } from '@carbon/icons-react'
import Text from 'lib/ui/Text'

import * as css from './styles'

interface INotification {
  title: string
  message: React.ReactNode
  icon: React.ReactNode
  color?: string
  startDate?: Date
  endDate?: Date
  backgroundColor?: string
}

const newNotification = (
  title: string,
  message: React.ReactNode = null,
  icon: React.ReactNode = null,
  color: string = null,
  startDate: Date = null,
  endDate: Date = null
): INotification => {
  if (!title || !message) {
    return null
  }
  return {
    title,
    message,
    icon,
    color,
    startDate,
    endDate
  }
}

const NotifyBanner = () => {
  const notifications = [
    newNotification(
      'Service Update',
      <Text size={'sm'} color='surface400'>
        A brief platform interruption yesterday was resolved. Still having
        issues? Clear your cache and refresh. Need help? Contact us via chat or{' '}
        <a href='mailto:customersuccess@alternativepayments.io'>
          customersuccess@alternativepayments.io
        </a>
        . We appreciate your understanding!
      </Text>,
      <WarningAlt width={16} height={16} />,
      'warning',
      null,
      new Date('2025-03-01T12:00:00Z')
    )
  ]

  return (
    <div css={css.wrapper}>
      {notifications
        .filter(notification => {
          const date = new Date().getTime()
          if (notification.startDate && notification.endDate) {
            return (
              date >= notification.startDate.getTime() &&
              date <= notification.endDate.getTime()
            )
          }
          if (notification.startDate && !notification.endDate) {
            return date >= notification.startDate.getTime()
          }
          if (!notification.startDate && notification.endDate) {
            return date <= notification.endDate.getTime()
          }
          return true
        })
        .map((notification: INotification) => (
          <div css={css.card(notification.color)} key={notification.title}>
            <div css={css.content}>
              <div css={css.icon(notification.color)}>{notification.icon}</div>
              <div css={css.text}>
                <Text
                  size={'md'}
                  fontWeight={'bold'}
                  color={notification.color + '300'}
                >
                  {notification.title}
                </Text>
                <div css={css.message}>{notification.message}</div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default NotifyBanner
